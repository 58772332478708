import React, { useEffect, useRef, useState } from 'react';
import { SlideOverTransition } from 'DesignSystem/Transitions';
import { TrashButton } from './TrashButton';
import { ConfirmButton } from './ConfirmButton';

export const CONFIRM_TIMEOUT = 800;

export interface DeleteButtonProps {
  ariaLabelDelete?: string;
  ariaLabelConfirm?: string;
  className?: string;
  dataTestid: string;
  initialState?: { hovering: boolean; confirmMode: boolean };
  onClick: () => void;
  onInitialClick?: () => void;
  tooltipClassName?: string;
  tooltipSize?: string;
  tooltipText?: string;
  transitionClassName?: string;
  [buttonProps: string]: unknown;
}

export const DeleteButton = ({
  ariaLabelDelete,
  ariaLabelConfirm,
  className = '',
  dataTestid,
  initialState = { confirmMode: false, hovering: false },
  onClick,
  onInitialClick = () => {},
  tooltipClassName = '',
  tooltipSize = 'sm',
  tooltipText = 'Delete Document Permanently',
  transitionClassName = '',
  ...buttonProps
}: DeleteButtonProps): JSX.Element => {
  const [isHovering, setHovering] = useState(initialState.hovering);
  const [isConfirmMode, setConfirmMode] = useState(initialState.confirmMode);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (!isHovering) {
      timer.current = setTimeout(() => setConfirmMode(false), CONFIRM_TIMEOUT);
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [isHovering]);

  const mouseHandlers = {
    onBlur: () => {},
    onFocus: () => {},
    onMouseOut: () => setHovering(false),
    onMouseOver: () => setHovering(true),
  };

  const handleInitialClick = () => {
    onInitialClick();
    setConfirmMode(true);
  };

  const size = buttonProps.children ? 'sm' : 'xs';

  return (
    // @ts-expect-error will be resolved when component is updated to typesecript
    <SlideOverTransition
      alternate={
        <ConfirmButton
          aria-label={ariaLabelConfirm}
          className={className}
          dataTestid={dataTestid}
          mouseHandlers={mouseHandlers}
          onClick={onClick}
          size={size}
          {...buttonProps}
        />
      }
      className={transitionClassName}
      isAlternateMode={isConfirmMode}
      normal={
        // @ts-expect-error will be resolved when component is updated to typesecript
        <TrashButton
          aria-label={ariaLabelDelete}
          className={className}
          dataTestid={dataTestid}
          mouseHandlers={mouseHandlers}
          onClick={handleInitialClick}
          tooltipClassName={tooltipClassName}
          tooltipSize={tooltipSize}
          tooltipText={tooltipText}
          {...buttonProps}
        />
      }
    />
  );
};
